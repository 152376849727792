/**
 * @description 券免邮适用场景
 * @readonly
 * @enum {number}
 * @property {number} ALL 全部商品 - 1
 * @property {number} PART 部分店铺 - 8
 */
export const shippingApplyForEnum = {
  UNKNOW: -1,
  ALL: 1,
  PART: 8
}

/**
 * @description 券适用店铺类型
 * @readonly
 * @enum {number}
 * @property {number} SELF 自营 - 1
 * @property {number} TRIPARTITE 三方 - 2
 * @property {number} SELF_AND_TRIPARTITE 自营和三方 - 3
 * @property {number} SELF_AND_TRIPARTITE 自营品类 - 4
 */
export const platformStoreTypeEnum = {
  UNKNOW: -1,
  SELF: 1,
  TRIPARTITE: 2,
  SELF_AND_TRIPARTITE: 3,
  SELF_CATEGORY: 4,
}

/**
 * @description 页面来源
 * @readonly
 * @enum {string}
 * @property {string} CHECKOUT 下单页 - checkout
 * @property {string} USER_COUPON 个人中心 - userCoupon
 * @property {string} CART 购物车 - cart
 */
export const PageSourceEnum = {
  CHECKOUT: 'checkout', 
  USER_COUPON: 'userCoupon',
  CART: 'cart',
}

/**
 * 免邮券的apply_for
 * 5: 减免最低邮费券
 * 6: 邮费全免券
 * 9: 指定运输方式减运费券
 */
export const FREE_SHIPPING_COUPON_APPLY_FOR = [ '5', '6', '9' ]
