<template>
  <div
    class="main-login-container page__login"
    :class="!isAsComponent ? 'page__login_route-container': ''"
  >
    <MainPage
      v-if="isHideMainPop"
      ref="mainPage"
      :is-hide-main-pop="isHideMainPop"
      :lang-text="langText"
      @BindEmail="showBindEmail"
    />
    <SDrawer
      v-else-if="isAsComponent"
      ref="aside"
      data-customSceneName="dialog_login"
      :no-header="true"
      class="login-pop-drawer"
      :show-close="false"
      :type="'full'"
      :visible="showIndex"
      :direction="'btt'"
      :immediately-render="true"
      @opened="openedDialogLogin"
    >
      <template slot="top"></template>
      <MainPage
        class="fsp-element"
        ref="mainPage"
        :lang-text="langText"
        :pop-config="popConfig"
        @BindEmail="showBindEmail"
      />
    </SDrawer>
    <LoginCommonDialogs
      ref="loginCommonDialogsRef" 
      @updateSignUpInfo="updateSignUp"
    />
    <!--loading-->
    <LazyMount mount-prop-name="show">
      <s-loading
        type="curpage"
        :show="isShowLoading"
        :append-to-body="true"
      />
    </LazyMount>
  </div>
</template>

<script>
import { mainPageMixin } from './mixin/index'
import '@login/common/common_init.js'

export default {
  name: 'LoginIndex',
  mixins: [ mainPageMixin ],
  methods: {
    openedDialogLogin () {
      if (typeof gbCommonInfo === 'undefined' || (typeof SaPageInfo === 'undefined' && typeof getSaPageInfo === 'undefined')) {
        return
      }
      if (typeof window.SHEIN_LOGIN === 'undefined' || typeof window.TPM === 'undefined') {
        return
      }
      const { OBSERVE_LOGIN_DIALOG_FSP_PAGES = [] } = gbCommonInfo
      const pageName = SaPageInfo?.page_name || getSaPageInfo?.page_name
      const needObserve = !OBSERVE_LOGIN_DIALOG_FSP_PAGES?.length || OBSERVE_LOGIN_DIALOG_FSP_PAGES.includes(pageName)
      if (!needObserve) {
        return
      }
      if (this.$refs.mainPage) {
        const startTime = window.SHEIN_LOGIN.navtrack_showLoginDialogStart || 0
        const endTime = Date.now()
        const value = endTime - startTime
        window.TPM?.run({
          marketing: 'ClientAnalytics',
          method: '_defineTrack',
          params: {
            data: {
              resource: 'custom-scene',
              data: [
                {
                  key_path: 'fsp',
                  values: {
                    num: value
                  }
                },
              ],
              page_name: 'dialog_login_v2'
            },
            options: {
              random: 1,
              immediate: true,
            },
          }
        })
      }
    }
  }
}
</script>

