import Vue from 'vue'
import Vuex from 'vuex'
import createState from './state'
import createMutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default function createStore() {
  const loginModule = {
    namespaced: true,
    state: createState(),
    mutations: createMutations(),
    actions
  }
  return new Vuex.Store({
    modules: {
      login: loginModule
    }
  })
}
